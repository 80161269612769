import Avatar from "@components/avatar"
import { X } from "react-feather"

export const ErrorToastContent = ({ message }) => {
  return (
    <div className="d-flex">
      <div className="me-1">
        <Avatar size="sm" color="danger" icon={<X size={12} />} />
      </div>
      <div className="d-flex flex-column">
        <span>{message}</span>
      </div>
    </div>
  )
}

export const SuccessToastContent = ({ message }) => {
  return (
    <div className="d-flex">
      <div className="me-1">
        <Avatar size="sm" color="success" icon={<X size={12} />} />
      </div>
      <div className="d-flex flex-column">
        <span>{message}</span>
      </div>
    </div>
  )
}
